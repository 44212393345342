body {
    background-color: #F8FAFD;
}

* {
    padding: 0;
    margin: 0;
    font-family: 'Montserrat', 'sans-serif';
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Montserrat', 'sans-serif';
}
